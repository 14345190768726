import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'

//element-ui
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

//tinymce
import tinymce from 'tinymce';
Vue.prototype.$tinymce = tinymce;

Vue.config.productionTip = false
Vue.use(ElementUI)

//路由拦截，拦截全部路由，每次操作路由都是被拦截进行判断

router.beforeEach((to,from,next)=>{
  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  }
  const token = localStorage.getItem("token");
  //筛选需要传token的路由，匹配route里面需要登录的路径，如果匹配到就是true
  if(to.matched.some(record => record.meta.requiresAuth) ){
    //根据token是否有，判断是否需要调到登录页面
    if(token){
      next()
    }else{
      if(from.path.startsWith('/school')){
        next({path:'/school/login'})
      }
      else if(from.path.startWith('/opac')){
        next({path:'/opac/login'})
      }
    }
  }else{
    next();
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
